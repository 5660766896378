<template>
  <div class="Real">
    <!-- 头部问题及banner -->
    <div class="banner" :style="{backgroundImage:'url(' + bannerBack + ')'}">
      <div class="t-c text2 top_text">RealPlayer 中国版服务停止，感谢大家对RealPlayer中国版的支持。</div>
      <div class="t-c text2 top_text">本网站下载均指向RealPlayer国际版，请您关注www.real.com，获取更多详情</div>
      <download-btn from="real"></download-btn>
      <img src="@/img/real-banner.png" alt="">
    </div>

    <!-- 内容介绍部分 -->
    <div class="content">
      <abort-real-content :show-list="list"></abort-real-content>
    </div>

    <!-- 底部pc模块 -->
    <div class="mobile-show pc-type">
      <div class="t-c mobile-show-title">一起变得更美好</div>
      <div class="bottom-top-img">
        <div>
          <img src="@/img/new_index07.png" alt="">
        </div>
        <div>
          <img src="@/img/new_index08.png" alt="">
        </div>
      </div>
      <!-- 文字模块 -->
      <div class="mobile-show-content">
        <!-- 左侧图片 -->
        <div class="msc-l">
          <div class="text10">RealPlayer Windows 版</div>
          <div class="p_ text11" @click="openLink('/realplayer',true)">了解更多 ›</div>
          <div class="text12">轻松管理和组织您的电脑媒体收藏，最快捷、最简单的电脑媒体播放器和管理器。一键从网站下载视频！</div>
          <download-btn from="real" style=""></download-btn>
        </div>
        <!-- 右侧模块 -->
        <div class="msc-r">
          <div class="text6">RealPlayer 移动版</div>
          <div class="p_ text7" @click="openLink('/realplayer-mobile',true)">了解更多 ›</div>
          <div class="text8">随时随地访问您的 RealPlayer 电脑端媒体库、同步和离线观看视频、投射到大屏幕、将您的视频备份到 RealPlayer Cloud 等等！</div>
          <div class="scancode">
            <div class="scancode-google" @click="openLink(toGoogleLink)">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
          <div class="t-c text9">使用照相手机扫描二维码，安装应用程序</div>
        </div>
        
      </div>
    </div>
     <!-- 底部mobile模块 -->
    <div class="mobile-show mobile-type">
      <div class="t-c mobile-show-title">一起变得更美好</div>
      <div class="bottom-top-img">
        <!-- 上面pc端 -->

        <!-- 下面移动端 -->
        <div class="bottom-top-img-mobile">
          <img src="@/img/real-bottom2.png" alt="">
        </div>
         <!-- 右侧模块 -->
        <div class="msc-r">
          <div class="c-b text5">新版本！</div>
          <div class="text6">RealPlayer 移动版</div>
          <div class="p_ text7" @click="openLink('/realplayer-mobile',true)">了解更多 ›</div>
          <div class="text8">RealPlayer 的必备伴侣。随时随地访问您的 RealPlayer 媒体库、同步和离线观看视频、投射到大屏幕、将您的视频备份到 RealPlayer Cloud 等等！</div>
          <div class="scancode pc-type">
            <div class="scancode-google" @click="openLink(toGoogleLink)">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
          <div class="scancode mobile-type">
            <div class="scancode-google" @click="openLink(toGoogleLink)" v-if="type == 'andriod'">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)" v-if="type == 'ios'">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
         
          <div class="t-c text9">使用照相手机扫描二维码，安装应用程序</div>
        </div>

      </div>
      <!-- 文字模块 -->
      <div class="mobile-show-content">
        
       
        
      </div>
    </div>
  </div>
</template>
<script>
  import AbortReal from "@/components/AbortReal.vue"
  import AbortRealContent from "@/components/RealContent.vue"
  import DownloadBtn from "@/components/DownloadBtn.vue"
  import settingLink from '@/js/global.js'
  export default {
   name: 'Real',
    data(){
      return{
        toGoogleLink:settingLink.googleLink,
        toAppStoreLink:settingLink.appStoreLink,
        bannerBack:require('@/img/realplayer-banner-back_realplayer.png'),
        // 模块展示内容
        /**
         *  1.蓝色小字  是否为下载 （默认为下载） is_blue
            2.大标题加粗黑色                     is_b_title
            3.小标题加粗黑色                     is_l_title
            4.内容                               is_content
            5.是否列表式                         is_list
         */
        list:{
          setting:{
            page:'real',
            type:true,// 是否带下载
          },
          content:[
              {
                img:require('@/img/new_index01.png'),
                direction:'left',// 图片在左or右 left  right
                list_content:[
                  {text:'全新！',type:'is_blue', },
                  {text:'更强大的视频下载器！',type:'is_b_title', },
                  {text:'随心下载',type:'is_l_title', },
                  {text:"选项更多，自定义下载分辨率、下载速度",type:'is_content', },
                  {text:"或只下载音频",type:'is_content', },
                  {text:'支持数千视频网站',type:'is_l_title', },
                  {text:"立即播放",type:'is_content', },
                  {text:"在RealPlayer播放器中直接播放网页视频",type:'is_content', },
                  {text:'优先热修复',type:'is_l_title', },
                  {text:"*该功能仅限尊享用户",type:'is_content', }
                ]
              },
              {
                img:require('@/img/new_index02.png'),
                page:'real',
                type:true,// 是否带下载
                direction:'right',// 图片在左or右 left  right
                list_content:[
                  {text:'全新！',type:'is_blue', },
                  {text:'一键下载到手机！',type:'is_b_title', },
                  {text:'最快，最简单，最有趣的方式来收藏你喜欢的视频。并将视频内容直接下载到手机端。',type:'is_l_title', },
                ]
              },
              {
                img:require('@/img/new_index03.png'),
                page:'real',
                type:true,// 是否带下载
                direction:'left',// 图片在左or右 left  right
                list_content:[
                  {text:'全新！',type:'is_blue', },
                  {text:'深色模式',type:'is_b_title', },
                  {text:'全新的UI设计',type:'is_l_title', },
                  {text:"自定义外观设置：深色、浅色以及系统跟随。",type:'is_content', },
                ]
              },
              {
                img:require('@/img/new_index04.png'),
                page:'real',
                type:true,// 是否带下载
                direction:'right',// 图片在左or右 left  right
                list_content:[
                  {text:'全新！',type:'is_blue', },
                  {text:'在大屏幕上欣赏您的视频！',type:'is_b_title', },
                  {text:"播放任意视频格式。使用 “投屏功能” 将您的视频内容直接投屏到电视上。在沙发上欣赏巨幕影像。",type:'is_content', },
                  {text:'RealPlayer 支持 CD 和 DVD 播放和刻录。',type:'is_content', },
                ]
              },
              {
                img:require('@/img/new_index06.png'),
                page:'real',
                type:true,// 是否带下载
                direction:'right',// 图片在左or右 left  right
                list_content:[
                  {text:'随时随地访问您的视频',type:'is_b_title', },
                  {text:'使用 RealPlayer 移动版随时访问媒体库。即使没有连接到家庭网络，也可以访问，通过流式传输同步您的媒体，让视频访问一键可及。 将您的手机视频同步到电脑。使用云备份确保您的内容安全且可访问。',type:'is_content', },
                ]
              }
            ]},
        // 当前设备类型
        type:'', // pc/andriod/ios
      }
    },
    components: {
      AbortReal,DownloadBtn,AbortRealContent
    },
    created:function(){
      window.scrollTo(0,0);
      var type = window.localStorage.getItem('type'); // 获取当前设备类型
      this.type = type;
    },
    methods:{
      openLink:function(url,type=false){
        if(type){
           this.$router.push(url);
        }else{
          window.open(url,'_blank');
        }
      }
    }
}
</script>
<style scoped>
@media screen and (min-width: 480px) {

  /* 新改动 */
  .text2{font: 36px Red Hat Display!important;color:#fff;padding-top: 20px;font-weight: 400;padding-bottom: 40px;}

  /* 新改动 */
  .mobile-type{display: none !important;}
  .text1{font: bold 22px Red Hat Display!important;}
  .text3{font: normal 14px Red Hat Display!important;color: #525252;}
  .text4{font: normal bold 14px/18px Red Hat Display;padding-top:20px;}
  .text4:hover{color: #337ab7;}
  .text5{font: bold 16px Red Hat Display!important;padding-top: 18px;}
  .text6{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text7{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px; color: #ffbe00 !important;}
  .text8{font: normal 15px Red Hat Display!important;color: #fff;}
  .text9{font: normal 15px Red Hat Display!important;color: #fff;padding-top: 26px;}

  .text10{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text11{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px; color: #ffbe00 !important;}
  .text12{font: normal 15px Red Hat Display!important;color: #fff;}
  .banner{
    min-height: 760px;
    margin: 0px auto;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 760px; 
    background-repeat: no-repeat	;
    /* padding: 0 5%; */
  }
  .banner img{
    display: block;
    max-height: 558px;
    margin: 0 auto 0;
  }

  .content{
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
  }

  .mobile-show{color: #fff;max-width:100%;margin:0 auto;padding-top:25px;padding: 25px 16%;background: transparent linear-gradient(180deg,#0098f1 0,#017ec7 100%) 0 0 no-repeat padding-box;}
  .mobile-show-title{
    font: 40px Red Hat Display!important;
    padding-bottom: 20px;
  }
  .mobile-show-content{
    display: flex;
    justify-content: space-between;
  }
  .msc-l{
    margin-right: 40px;
  }
  .msc-r img{max-height: 433px!important;}

  .bottom-top-img{display: flex;justify-content: space-between;}
  .bottom-top-img div{width: 50%;}
  .bottom-top-img img{margin: 0 auto;}
  .bottom-top-img div:nth-child(1) img{display: block;width:450px;height: 390px;}
  .bottom-top-img div:nth-child(2) img{display: block;width:200px !important;height: 390px;}

  /* 二维码 */
  .scancode{display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-google img:nth-child(2){width: 125px;height: 125px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-appstore img:nth-child(2){width: 125px;height: 125px;}
}
@media screen and (min-width: 320px) and (max-width:480px){
  .pc-type{display: none !important;}
  .text1{font: bold 12px Red Hat Display!important;}
  .text2{font: 22px Red Hat Display!important;color:#fff;padding-top: 20px;font-weight: 400;}
  .text3{font: normal 14px Red Hat Display!important;color: #525252;}
  .text4{font: normal bold 14px/18px Red Hat Display;padding-top:20px;}
  .text4:hover{color: #337ab7;}
  .text5{font: bold 16px Red Hat Display!important;padding-top: 18px;color: #ffbe00 !important;}
  .text6{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text7{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #ffbe00!important; }
  .text8{font: normal 15px Red Hat Display!important;color: #fff;}
  .text9{font: normal 15px Red Hat Display!important;color: #fff;padding-top: 26px;}

  .text10{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text11{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #00a7ff!important;}
  .text12{font: normal 15px Red Hat Display!important;color: #525252;}
  .banner{
    height: 300px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 300px; 
    background-repeat: no-repeat	;
    padding: 0 10%;
  }
  .banner img{
    display: block;
    width: 100%;
    max-height: 258px;
    margin: 0 auto;
  }

  .content{
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
  }

  .mobile-show{color: #fff;max-width:100%;margin:0 auto;padding-top:25px;padding: 25px 16%;background: transparent linear-gradient(180deg,#0098f1 0,#017ec7 100%) 0 0 no-repeat padding-box;}

  .mobile-show-title{
    font: bold 30px Red Hat Display!important;
    padding-bottom: 20px;
  }
  .mobile-show-content{
    display: flex;
    justify-content: space-between;
  }
  .msc-l{
    margin-right: 40px;
  }
  .msc-r img{max-height: 433px!important;}

  .bottom-top-img{width: 90%;margin: 0 auto;}
  .bottom-top-img div{width: 100%;}
  .bottom-top-img img{margin: 0 auto;}
  .bottom-top-img-pc img{display: block;width:90%;}
  .bottom-top-img-mobile img{display: block;width:90%;}

  /* 二维码 */
  .scancode{display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-google img:nth-child(2){width: 125px;height: 125px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-appstore img:nth-child(2){width: 125px;height: 125px;}
}
  

</style>